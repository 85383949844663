import React, { ReactElement, ReactNode } from "react";
import Head from "next/head";
import ThriftyAdviceHeader from "@components/thriftyadvice/thriftyAdviceHeader";
import { useDispatch, useSelector } from "react-redux";
import { setModal } from "@redux/shared/actions";
import { RootState } from "@redux/reducers";
import dynamic from "next/dynamic";
import ThriftyAdviceLogo from "@components/thriftyadvice/logo";
import Mobile from "@components/shared/blogPost/sideWidget/mobile";
import ThriftyAdviceFooter from "@components/thriftyadvice/footer";
const Modal = dynamic(() => import("@components/shared/modal"));
const CloseIcon = dynamic(() => import("@components/svg/closeIcon"));

import PrivacyPolicy from "@components/shared/legal/privacyPolicy";
import style from "@components/thriftyadvice/privacy-policy/legal.module.scss";

export default function ThriftyAdviceWrapper({
    children,
    fullMaxWidth = false,
}: {
    children: ReactNode;
    fullMaxWidth?: boolean;
}): ReactElement {
    const dispatch = useDispatch();

    const handleClose = () => {
        dispatch(setModal({ active: false, content: undefined }));
    };
    const { active: modalActive, content: modalContent } = useSelector(
        (state: RootState) => state.rootReducer.shared.modal,
    );

    const getModalContent = () => {
        switch (modalContent) {
            case "privacy":
                return <PrivacyPolicy mainWrapperClassName={style["legal"]} />;

            case "sideWidget":
                return <Mobile />;
            default:
                return null;
        }
    };

    return (
        <>
            <Head>
                <link rel="preconnect" href="https://fonts.googleapis.com" />
                <link rel="preconnect" href="https://fonts.gstatic.com" />

                <link
                    href="https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap"
                    rel="stylesheet"
                ></link>
            </Head>

            <div style={{ fontFamily: `'Roboto', sans-serif` }}>
                <ThriftyAdviceHeader boxShadow="0px -2px 10px #888888">
                    <ThriftyAdviceLogo />
                </ThriftyAdviceHeader>
                <main
                    className={`${
                        fullMaxWidth ? "max-w-7xl" : "max-w-[950px]"
                    }  mx-auto block px-4vw lg:px-4 mt-10`}
                >
                    {children}
                </main>
                <ThriftyAdviceFooter />
                <Modal
                    onClose={handleClose}
                    active={modalActive}
                    closeIcon={<CloseIcon />}
                    backdropClosable
                    modalStyles={{
                        display: "flex",
                        flexDirection: "column",
                        maxHeight:
                            modalContent === "sideWidget" ? "80vh" : "100%",
                    }}
                    modalClassName="h-[90vh] rounded"
                    bodyStyles={{ overflowY: "scroll" }}
                    width={900}
                    header={
                        modalContent === "privacy"
                            ? ""
                            : modalContent === "sideWidget"
                            ? "Contents"
                            : undefined
                    }
                >
                    {getModalContent()}
                </Modal>
            </div>
        </>
    );
}
